
.post-modal {
  .ql-editor {
    min-height: 250px;
  }
  
  .ql-editor h3 {
    margin-top: 10px !important;
  }  
}
@primary-color: #4a90e2;