.admin-layout {
    .header {
        background: #fff;
        padding: 0 20px;
        .logo {
            height: 48px;
            padding-top: 0px;
        }
    }
    .sider-layout-background {
        background: #fff;
        margin-top: 20px;
        margin-left: 20px;
    }
}
@primary-color: #4a90e2;