.login-page {
  background-color: #f8f8f8;
  width: 100% !important;

  .login-form-button {
    font-weight: bold;
  }

  .login-form-forgot {
    font-weight: bold;
  }
  .site-form-item-icon {
    font-size: 16;
    color: rgba(0, 0, 0, 0.25);
  }
}
.paddingCol {
  padding: 0 10vw;
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    padding: 0 5vw;
  }
}

@primary-color: #4a90e2;