.jobs-page {
    .ql-editor {
        min-height: 200px;
    }

    .ant-statistic-content-value-int {
        font-size: 15px;
    }

    .site-badge-count-4 .ant-badge-count {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
    }
}

.summary-cell {
    text-align: end;
    font-weight: bold;
}
@primary-color: #4a90e2;