.post-page {
  margin-bottom: 40px;
  .filter {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-top: 8px;
  }

  .filter-item {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 400;
      margin-top: 10px;
  }
  .longform {
    .ant-card-body {
      padding: 10px;
    }
  }
}


@primary-color: #4a90e2;